// https://www.youtube.com/watch?v=f7T48W0cwXM: how to build a instagram clone with react js and for beginners
import React, { useEffect, Suspense } from "react";
import "./App.css";
import "./i18n";
import { auth } from "./firebase";
// import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Header";
import Login from "./Login";
import Feed from "./Feed";
import UploadPage from "./UploadPage";
import SinglePost from "./SinglePost";
import Intro from "./Intro";
// import { Helmet, HelmetProvider } from "react-helmet-async";

import { useStateValue } from "./StateProvider";

function App() {
  // const classes = useStyles();
  const [{ user }, dispatch] = useStateValue();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // then user is logged in
        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        // then user is logged out
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    });

    // any clean up ops here
    return () => {
      unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="app">
      <Suspense fallback={null}>
        <Router>
          <Header />
          <Switch>
            <Route path="/post/:postId" component={SinglePost}>
              {/* let { postId } = useParams();
            <h1>Post {postId}</h1> */}
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/upload">{user ? <UploadPage /> : <Login />}</Route>
            <Route path="/">
              {!user && <Intro />} <Feed />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
