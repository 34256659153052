import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

import firebase from "firebase";
import { storage, db } from "./firebase";
import "./UploadPage.css";
import UploadCard from "./UploadCard";

import "./Intro.css";
import { useTranslation } from "react-i18next";

import { useStateValue } from "./StateProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "90%",
    maxWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function UploadPage() {
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState("");
  // const [cards, setCards] = useState([]);
  const [cardsWithFile, setCardsWithFile] = useState([]);
  const [postUrl, setPostUrl] = useState("");
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const history = useHistory();

  const [{ user }] = useStateValue();
  const { t } = useTranslation();

  const handleSelectFile = (e) => {
    // pick first one if selected multiple files
    if (e.target.files[0]) {
      addCard({ card: { steps: [], imageUrl: "" }, file: e.target.files[0] });
    } else {
      alert("image is null");
    }
  };

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const postGrim = () => {
    //post image inside db
    db.collection("posts")
      .add({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        title: title,
        imageUrl: cardsWithFile[0].card.imageUrl,
        username: user.displayName,
        userId: user.uid,
        // cards: cards.map((card) => ({ ...card, file: 0 })),
        cards: cardsWithFile.map((cardWithFile) => cardWithFile.card),
        views: 1,
      })
      .then(function (docRef) {
        setProgress(0);
        setTitle("");
        setCardsWithFile([]);
        setPostUrl(docRef.id);
      });
  };

  const handleUpload = () => {
    if (title.length === 0) {
      alert(t("enter_a_title.label"));
      return;
    }

    let totalCards = cardsWithFile.length;
    let completedCards = 0;
    cardsWithFile.map((cardWithFile, index) => {
      var uploadFilename =
        user.uid + "_" + Date.now() + "_" + cardWithFile.file.name;
      const uploadTask = storage
        .ref(`images/${uploadFilename}`)
        .put(cardWithFile.file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress function ...
          const progress = Math.round(
            (index / totalCards +
              snapshot.bytesTransferred / snapshot.totalBytes / totalCards) *
              100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
          alert(error.message);
        },
        () => {
          // complete function
          storage
            .ref("images")
            .child(uploadFilename)
            .getDownloadURL()
            .then((url) => {
              cardsWithFile[index].card.imageUrl = url;
              completedCards++;

              if (totalCards == completedCards) {
                postGrim();
              }
            });
        }
      );
    });
  };

  const addCard = (card) => {
    setCardsWithFile([...cardsWithFile, card]);
  };

  const addStep = (cardId, step) => {
    cardsWithFile[cardId].card.steps = [
      ...cardsWithFile[cardId].card.steps,
      step,
    ];
    setCardsWithFile(cardsWithFile);
  };

  const removeCard = (id) => {
    setCardsWithFile(
      cardsWithFile.filter((c, index) => {
        return index !== id;
      })
    );
  };

  const removeStep = (cardId, stepId) => {
    // cardsWithFile[cardId].card.steps = cardsWithFile[cardId].card.steps.filter(
    //   (c, index) => {
    //     return index !== stepId;
    //   }
    // );
    var tempCards = [...cardsWithFile];
    tempCards[cardId].card.steps = tempCards[cardId].card.steps.splice(
      stepId,
      1
    );

    setCardsWithFile(tempCards);
  };

  const handleClose = () => {
    setPostUrl("");
    history.push("/");
  };

  return (
    <div className="uploadpage">
      <input
        className="uploadpage__title"
        type="text"
        placeholder={t("enter_a_title.label")}
        onChange={(event) => setTitle(event.target.value)}
        value={title}
      />

      <div className="uploadpage__header">
        <div className="uploadpage__username">
          <Avatar
            className="uploadpage__avatar"
            alt={user.displayName}
            src="/static/images/avatar/1.jpg"
          ></Avatar>
          <h3>{user.displayName}</h3>
        </div>
        <div className="uploadpage__views">views: 1</div>
      </div>

      <Modal open={postUrl.length > 0} onClose={() => setPostUrl("")}>
        <div style={modalStyle} className={classes.paper}>
          <form className="uploadpage__postresult">
            <p>{t("url_is.label")}</p>
            <br></br>
            <p>
              <strong>https://howcow.io/post/{postUrl}</strong>
            </p>
            <br></br>
            <div className="uploadpage__buttons">
              <Button type="submit" onClick={handleClose}>
                {t("close.label")}
              </Button>
              <Button
                onClick={navigator.clipboard.writeText(
                  "https://howcow.io/post/" + postUrl
                )}
              >
                {t("copy_url.label")}
              </Button>
            </div>
          </form>
        </div>
      </Modal>

      {cardsWithFile.map((cardWithFile, cardId) => (
        <UploadCard
          key={cardId}
          cardId={cardId}
          cardWithFile={cardWithFile}
          removeStep={removeStep}
          addStep={addStep}
          removeCard={removeCard}
        />
      ))}

      {/* <input type="file" onChange={handleSelectFile} accept="image/*" /> */}

      <progress className="uploadpage__progress" value={progress} max="100" />

      <div className="uploadpage__footer">
        <Button onClick={handleUpload}>{t("post.label")}</Button>
        <div>
          <input
            id="upalodpage__footer__input_file"
            type="file"
            accept="image/*"
            onChange={handleSelectFile}
            hidden
          ></input>
          <label
            htmlFor="upalodpage__footer__input_file"
            className="uploadpage__footer__input__label"
          >
            <span>{t("add_a_picture.label")}</span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default UploadPage;
