import React from "react";
import "./Intro.css";
import { useTranslation } from "react-i18next";

function Intro() {
  const { t } = useTranslation();

  return (
    <div>
      <p className="intro">{t("intro1.label")}</p>
      <p className="intro">{t("intro2.label")}</p>
      <p className="intro">{t("intro3.label")}</p>
      <p className="intro">{t("intro4.label")}</p>
      <p className="intro">{t("intro5.label")}</p>
      <p className="intro">{t("intro6.label")}</p>
      <p className="intro">{t("intro7.label")}</p>
    </div>
  );
}

export default Intro;
