import React, { useState, useEffect, useRef } from "react";
import "./Post.css";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";

function Post({ post }) {
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const ref = useRef(null);
  var radius = 30;

  useEffect(() => {
    setImageHeight(ref.current.offsetHeight);
    setImageWidth(ref.current.offsetWidth);
  }, []);

  const getImageWidth = () => {
    // var divElement = document.getElementsByClassName("post__square__svg")[0];
    // return divElement ? divElement.offsetWidth : window.innerWidth;
    return imageWidth;
  };
  const getImageHeight = () => {
    // var divElement = document.getElementsByClassName("post__square__svg")[0];
    // return divElement ? divElement.offsetHeight : window.innerWidth;
    return imageHeight;
  };

  const getTextPosition = (coordinate, imageSize) => {
    var delta = radius;
    if (coordinate < 25) delta = radius;
    else if (coordinate > 75) delta = -1 * radius;

    return (coordinate * imageSize) / 100 + delta;
  };

  return (
    <div className="post">
      <Link className="post__link" to={"/post/" + post.postId}>
        <h2 className="post__text">
          {post.title}[{post.cards.length}]
        </h2>

        <div className="post__square" ref={ref}>
          <svg className="post__square__svg">
            <image width="100%" height="100%" href={post.imageUrl} />
            {post.cards[0].steps &&
              post.cards[0].steps.map((step, index) => (
                <g key={index}>
                  <circle
                    cx={(step.x * getImageWidth()) / 100}
                    cy={(step.y * getImageHeight()) / 100}
                    r={radius}
                    stroke="#00000088"
                    strokeWidth="7"
                    fill="none"
                  />
                  <circle
                    cx={(step.x * getImageWidth()) / 100}
                    cy={(step.y * getImageHeight()) / 100}
                    r={radius}
                    stroke="#FFFFFF"
                    strokeWidth="1"
                    fill="none"
                  />
                  <circle
                    cx={getTextPosition(step.x, getImageWidth())}
                    cy={getTextPosition(step.y, getImageHeight())}
                    r={10}
                    stroke="black"
                    strokeWidth="1"
                    fill="#FFFFFF"
                  />
                  <text
                    x={getTextPosition(step.x, getImageWidth()) - 4}
                    y={getTextPosition(step.y, getImageHeight()) + 5}
                    fill="black"
                  >
                    {index + 1}
                  </text>
                </g>
              ))}
          </svg>
        </div>

        <div className="post__header">
          <div className="post__username">
            <Avatar
              className="post__avatar"
              alt={post.username}
              src="/static/images/avatar/1.jpg"
            ></Avatar>
            <h3>{post.username}</h3>
          </div>

          <div className="post__views">views: {post.views}</div>
        </div>
      </Link>
    </div>
  );
}

export default Post;
