import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import firebase from "firebase";
import "./SinglePost.css";
import CardView from "./Components/CardView";
// import { useStateValue } from "./StateProvider";
import Avatar from "@material-ui/core/Avatar";

function SinglePost({ match }) {
  let { postId } = match.params;

  const [post, setPost] = useState();
  // const [{ user }, dispatch] = useStateValue();

  useEffect(() => {
    // this is where the code runs
    db.collection("posts")
      .doc(postId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setPost({ ...doc.data(), postId: doc.id });
        }
      });

    const increment = firebase.firestore.FieldValue.increment(1);
    db.collection("posts").doc(postId).update({ views: increment });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // condition [] runs once when open the page.

  return (
    <div className="feed">
      <h2 className="singlepost__title">
        {post && post.title}[{post && post.cards.length}]
      </h2>

      <div className="singlepost__header">
        <div className="singlepost__username">
          <Avatar
            className="singlepost__avatar"
            alt={post && post.username}
            src="/static/images/avatar/1.jpg"
          ></Avatar>
          <h3>{post && post.username}</h3>
        </div>
        <div className="singlepost__views">views: {post && post.views}</div>
      </div>
      {post &&
        post.cards.map((card, index) => <CardView key={index} card={card} />)}
    </div>
  );
}

export default SinglePost;
