import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth } from "./firebase";
import { Button, Input } from "@material-ui/core";
import "./Login.css";
import "./Intro.css";
import { useTranslation } from "react-i18next";

// import { useStateValue } from "./StateProvider";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  // const [{ user }, dispatch] = useStateValue();
  const { t } = useTranslation();

  const history = useHistory();

  const signIn = (event) => {
    event.preventDefault(); // this stops the refresh

    auth
      .signInWithEmailAndPassword(email, password)
      .then((authUser) => {
        history.push("/");
      })
      .catch((error) => alert(error.message));
  };

  const signUp = (event) => {
    event.preventDefault(); // this stops the refresh

    if (!username || username.length < 3) {
      alert(t("please_add_username.label"));

      return;
    }

    auth
      .createUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        authUser.user
          .updateProfile({
            displayName: username,
          })
          .catch((error) => {
            alert(error.message);
          })
          .finally(() => history.push("/"));
      })
      .catch((e) => alert(e.message));
  };

  return (
    <div className="login">
      <Link to="/">
        <center>
          <img
            className="login__logo"
            src="https://firebasestorage.googleapis.com/v0/b/grim-3ab2a.appspot.com/o/logos%2Flogo.png?alt=media&token=fc4ef119-3be6-416f-9347-e266d5cc824f"
            alt=""
          />
        </center>
      </Link>
      <div className="login__container">
        <form className="login__signup">
          <Input
            className="login__input"
            placeholder="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            className="login__input"
            placeholder={t("password_placeholder.label")}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            className="login__input"
            placeholder={t("username_placeholder.label")}
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <div className="login__buttonContainer">
            <Button type="submit" onClick={signIn}>
              {t("sign_in.label")}
            </Button>
            <Button onClick={signUp}>{t("sign_up.label")}</Button>
          </div>
        </form>
        <p>
          By signing in you agree to Grim's Conditions of Use & Sale. Please see
          our Privacy Notic, our Cookies Notice and our Interest-Based Ads
          Notice.
        </p>
      </div>
    </div>
  );
}

export default Login;
