import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyBfpL1pyOKJzY0wFVz7V8syyI6MibQlIHs",
    authDomain: "grim-3ab2a.firebaseapp.com",
    databaseURL: "https://grim-3ab2a.firebaseio.com",
    projectId: "grim-3ab2a",
    storageBucket: "grim-3ab2a.appspot.com",
    messagingSenderId: "944574896984",
    appId: "1:944574896984:web:9e36e3a8d48061e87ba32d",
    measurementId: "G-3NMTRFX2K5"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };


