import React from "react";
import "./Header.css";
import { Link, useHistory } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";
import { useTranslation } from "react-i18next";

function Header() {
  const [{ user }] = useStateValue();
  const { t } = useTranslation();
  const history = useHistory();

  const login = () => {
    if (user) {
      auth.signOut();
    } else {
      history.push("/login");
    }
  };

  return (
    <nav className="header">
      <Link to="/">
        <img
          className="header__logo"
          src="https://firebasestorage.googleapis.com/v0/b/grim-3ab2a.appspot.com/o/logos%2Flogo.png?alt=media&token=fc4ef119-3be6-416f-9347-e266d5cc824f"
          alt=""
        />
      </Link>
      {user ? (
        <div onClick={login} className="header__option">
          {/* <span className="header__optionLineOne">{user.displayName}</span> */}
          <span className="header__optionLineOne">{t("log_out.label")}</span>
        </div>
      ) : (
        <Link to={!user && "/login"} className="header__login">
          <div className="header__option">
            <span className="header__optionLineOne">
              {t("sign_up_in.label")}
            </span>
          </div>
        </Link>
      )}
      {user ? (
        <Link to="/upload" className="header__login">
          <div className="header__option">
            <span className="header__optionLineOne">{t("upload.label")}</span>
          </div>
        </Link>
      ) : (
        <span></span>
      )}
    </nav>
  );
}

export default Header;
