import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { db } from "./firebase";
import Post from "./Post";
// import { useStateValue } from "./StateProvider";

function Feed() {
  const [posts, setPosts] = useState([]);
  // const [{ user }, dispatch] = useStateValue();

  useEffect(() => {
    // get real time update
    // db.collection("posts")
    //   .orderBy("timestamp", "desc")
    //   .onSnapshot((snapshot) => {
    //     // every time a new post is added, this code fires...
    //     setPosts(
    //       snapshot.docs.map((doc) => ({
    //         id: doc.id,
    //         post: { ...doc.data(), postId: doc.id },
    //       }))
    //     );
    //   });

    db.collection("posts")
      .orderBy("timestamp", "desc")
      .get()
      .then((snapshot) => {
        // every time a new post is added, this code fires...
        setPosts(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            post: { ...doc.data(), postId: doc.id },
          }))
        );
      });
  }, []); // condition [] runs once when open the page.

  return (
    <div className="feed">
      <Helmet>
        <meta property="og:type" content="website" />
      </Helmet>
      {posts.map(({ id, post }) => (
        <Post key={id} post={post} />
      ))}
    </div>
  );
}

export default Feed;
