import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import "./CardView.css";

function CardView({ card }) {
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const ref = useRef(null);
  var radius = 30;

  useEffect(() => {
    setImageHeight(ref.current.offsetHeight);
    setImageWidth(ref.current.offsetWidth);
  }, []);

  const getTextPosition = (coordinate, imageSize) => {
    var delta = radius;
    if (coordinate < 25) delta = radius;
    else if (coordinate > 75) delta = -1 * radius;

    return (coordinate * imageSize) / 100 + delta;
  };

  const handleEdit = () => {};

  return (
    <div className="cardview">
      <div className="cardview__square" ref={ref}>
        <svg className="cardview__square__svg">
          <image width="100%" height="100%" href={card.imageUrl} />
          {card.steps &&
            card.steps.map((step, index) => (
              <g key={index}>
                <circle
                  // key={index}
                  cx={(step.x * imageWidth) / 100}
                  cy={(step.y * imageHeight) / 100}
                  r={radius}
                  stroke="#00000088"
                  strokeWidth="7"
                  fill="none"
                />
                <circle
                  // key={index}
                  cx={(step.x * imageWidth) / 100}
                  cy={(step.y * imageHeight) / 100}
                  r={radius}
                  stroke="#FFFFFF"
                  strokeWidth="1"
                  fill="none"
                />
                <circle
                  // key={index}
                  cx={getTextPosition(step.x, imageWidth)}
                  cy={getTextPosition(step.y, imageHeight)}
                  r={10}
                  stroke="black"
                  strokeWidth="1"
                  fill="#FFFFFF"
                />
                <text
                  // key={index}
                  x={getTextPosition(step.x, imageWidth) - 4}
                  y={getTextPosition(step.y, imageHeight) + 5}
                  fill="black"
                >
                  {index + 1}
                </text>
              </g>
            ))}
        </svg>
      </div>

      <div className="cardview__steps">
        {card.steps.map((step, index) => (
          <div>
            <p className="cardview__steps__text" key={index}>
              <strong>{index + 1}: </strong>
              {step.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardView;
