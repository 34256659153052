import React, { useState, useEffect, useRef } from "react";
import "./UploadCard.css";
import Modal from "@material-ui/core/Modal";
import { Button, Input } from "@material-ui/core";
// import Avatar from "@material-ui/core/Avatar";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "80%",
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
  },
}));

function UploadCard({ cardId, cardWithFile, removeStep, addStep, removeCard }) {
  const imgRef = useRef(null);
  const classes = useStyles();
  const [openAddStep, setOpenAddStep] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [newStep, setNewStep] = useState({});
  const [imageWidth, setImageWidth] = useState(1);
  const [imageHeight, setImageHeight] = useState(1);
  const { t } = useTranslation();
  var isSwipe = false;
  var radius = 30;
  var editIndex = -1;

  useEffect(() => {
    if (imgRef.current) {
      // let imgHeight = imgRef.current.offsetHeight;
      // let imgWidth = imgRef.current.offsetWidth;
    }
  }, [imgRef]);

  const getTextPosition = (coordinate, imageSize) => {
    var delta = radius;
    if (coordinate < 25) delta = radius;
    else if (coordinate > 75) delta = -1 * radius;

    return (coordinate * imageSize) / 100 + delta;
  };

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const addNewStep = (event) => {
    setOpenAddStep(false);
    addStep(cardId, newStep);
  };

  const onTouchEnd = (event, a) => {
    if (isSwipe === true) {
      isSwipe = false;
      return;
    }

    event.preventDefault(); // prevent the event firing onClose()
    setOpenAddStep(true);

    var bcr = event.target.getBoundingClientRect();
    // console.log("x ", bcr.x);
    // console.log("y ", bcr.y);
    // console.log(
    //   "client X",
    //   event.changedTouches[0].clientX,
    //   " ",
    //   event.changedTouches[0].clientY
    // );
    // console.log(
    //   "coordinates ",
    //   event.changedTouches[0].clientX - bcr.x,
    //   " ",
    //   event.changedTouches[0].clientY - bcr.y
    // );

    var x = event.changedTouches[0].clientX - bcr.x;
    var y = event.changedTouches[0].clientY - bcr.y;

    addNewStepWithCoordinates(x, y);
  };

  const onTouchMove = (event) => {
    isSwipe = true;
  };

  const onMouseUp = (event, a) => {
    event.preventDefault(); // prevent event firing onClose()
    setOpenAddStep(true);

    addNewStepWithCoordinates(
      event.nativeEvent.offsetX,
      event.nativeEvent.offsetY
    );
  };

  const addNewStepWithCoordinates = (x, y) => {
    var divElement = document.getElementsByClassName("uploadcard__square")[0];
    setImageWidth(divElement.offsetWidth);
    setImageHeight(divElement.offsetHeight);
    setOpenAddStep(true);

    var xPercent = ((100 * x) / divElement.offsetWidth).toFixed(3);

    var yPercent = ((100 * y) / divElement.offsetHeight).toFixed(3);

    setNewStep({ ...newStep, x: xPercent, y: yPercent });
  };

  return (
    <div className="uploadCard">
      <div className="uploadCard__header">
        <div>
          <h4 className="uploadCard__header__text">
            <strong>{cardWithFile.file.name}</strong>
          </h4>
        </div>
        <button
          className="uploadCard__header__button"
          onClick={() => removeCard(cardId)}
        >
          Remove
        </button>
      </div>

      <Modal open={openAddStep} onClose={() => setOpenAddStep(false)}>
        <div style={modalStyle} className={classes.paper}>
          <form className="uploadCard__addStep">
            <Input
              className="uploadCard__addStep__input"
              placeholder={t("tell_us_how_to_do.label")}
              type="text"
              // value={newStep.text}
              onChange={(e) => setNewStep({ ...newStep, text: e.target.value })}
            />
            <Button
              className="uploadCard__addStep__button"
              type="submit"
              onClick={addNewStep}
            >
              ADD
            </Button>
          </form>
        </div>
      </Modal>

      <div className="uploadcard__square">
        <svg className="uploadcard__square__svg">
          <image
            width="100%"
            height="100%"
            href={URL.createObjectURL(cardWithFile.file)}
            onMouseUp={onMouseUp}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove} // to detect swipe
          />
          {cardWithFile.card.steps &&
            cardWithFile.card.steps.map((step, index) => (
              <g key={index}>
                <circle
                  cx={(step.x * imageWidth) / 100}
                  cy={(step.y * imageHeight) / 100}
                  r={radius}
                  stroke="#00000088"
                  strokeWidth="7"
                  fill="none"
                />
                <circle
                  cx={(step.x * imageWidth) / 100}
                  cy={(step.y * imageHeight) / 100}
                  r={radius}
                  stroke="#FFFFFF"
                  strokeWidth="1"
                  fill="none"
                />
                <circle
                  cx={getTextPosition(step.x, imageWidth) + 4}
                  cy={getTextPosition(step.y, imageHeight) - 5}
                  r={10}
                  stroke="black"
                  strokeWidth="1"
                  fill="#FFFFFF88"
                />
                <text
                  x={getTextPosition(step.x, imageWidth)}
                  y={getTextPosition(step.y, imageHeight)}
                  fill="black"
                >
                  {index + 1}
                </text>
              </g>
            ))}
        </svg>
      </div>

      <div className="uploadCard__footer">
        {cardWithFile.card.steps.map((step, index) => (
          <div className="uploadCard__footer__step" key={index}>
            <div className="uploadCard__footer__step__text">
              <strong>{index + 1}: </strong>
              {step.text}
            </div>
            {/* <button
              className="uploadCard__footer__step__button"
              onClick={() => removeStep(cardId, index)}
              // onClick={navigator.clipboard.writeText("tis is test")}
            >
              Remove
            </button> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default UploadCard;
